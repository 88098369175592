import *  as ActionTypes from "./vendorActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    vendors: []
};

export const vendorReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_VENDOR:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_VENDOR_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_VENDOR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.ASSIGN_CATEGORY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.ASSIGN_CATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.ASSIGN_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_VENDOR:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_VENDOR_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_VENDOR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_VENDOR:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_VENDOR_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_VENDOR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                vendors: action?.payload?.data || []
            }
        case ActionTypes.UPDATE_VENDOR:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_VENDOR_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_VENDOR_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state;
    }
}