export const CREATE_VENDOR = "CREATE_VENDOR";
export const CREATE_VENDOR_FAILURE = "CREATE_VENDOR_FAILURE";
export const CREATE_VENDOR_SUCCESS = "CREATE_VENDOR_SUCCESS";

export const DELETE_VENDOR = "DELETE_VENDOR";
export const DELETE_VENDOR_FAILURE = "DELETE_VENDOR_FAILURE";
export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS";

export const GET_VENDOR = "GET_VENDOR";
export const GET_VENDOR_FAILURE = "GET_VENDOR_FAILURE";
export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS";

export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const UPDATE_VENDOR_FAILURE = "UPDATE_VENDOR_FAILURE";
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";

export const ASSIGN_CATEGORY = "ASSIGN_CATEGORY";
export const ASSIGN_CATEGORY_FAILURE = "ASSIGN_CATEGORY_FAILURE";
export const ASSIGN_CATEGORY_SUCCESS = "ASSIGN_CATEGORY_SUCCESS";