export const CREATE_MANUFATURER = "CREATE_MANUFATURER";
export const CREATE_MANUFATURER_FAILURE = "CREATE_MANUFATURER_FAILURE";
export const CREATE_MANUFATURER_SUCCESS = "CREATE_MANUFATURER_SUCCESS";

export const DELETE_MANUFATURER = "DELETE_MANUFATURER";
export const DELETE_MANUFATURER_FAILURE = "DELETE_MANUFATURER_FAILURE";
export const DELETE_MANUFATURER_SUCCESS = "DELETE_MANUFATURER_SUCCESS";

export const GET_MANUFATURER = "GET_MANUFATURER";
export const GET_MANUFATURER_FAILURE = "GET_MANUFATURER_FAILURE";
export const GET_MANUFATURER_SUCCESS = "GET_MANUFATURER_SUCCESS";

export const UPDATE_MANUFATURER = "UPDATE_MANUFATURER";
export const UPDATE_MANUFATURER_FAILURE = "UPDATE_MANUFATURER_FAILURE";
export const UPDATE_MANUFATURER_SUCCESS = "UPDATE_MANUFATURER_SUCCESS";
