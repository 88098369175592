import React, { lazy, Suspense } from "react";
import { ProtectedRoute } from './app/protected.route';
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";

const LoginContainer = lazy(() => import('./view/login/login.container'));
const DashboardContainer = lazy(() => import('./view/dashboard/dashboard.container'));
const ForgotPasswordContainer = lazy(() => import('./view/forgot-password/forgot-password.container'));
const FaqContainer = lazy(() => import('./view/masters/faq/faq.container'));
const PageContainer = lazy(() => import('./view/masters/pages/page.container'));
const RoleContainer = lazy(() => import('./view/setting/role/role.container'));
const SubAdminContainer = lazy(()=> import('./view/users/sub-admin/sub-admin.container'));
const ContactUsContainer = lazy(() => import('./view/contact-us/contactus-table.container'));
const UpdatePasswordContainer = lazy(() => import('./view/setting/password-setting/password-setting.container'));
const ProfileSettingContainer = lazy(() => import('./view/setting/profile-setting/profile-setting.container'));
const VendorContainer = lazy(() => import('./view/vendor/vendor.container'));
const ManufacturerContainer = lazy(() => import('./view/manufacturer/manufacturer.container'));
const CategoryContainer = lazy(() => import('./view/category/category.container'));
const ProductContainer = lazy(() => import('./view/product/product.container'));




const RoutesPath = () => {
    return (
        <Box>
            <Routes>
                <Route path="/login" element={<Suspense fallback={<>...</>}><LoginContainer /></Suspense>} />
                <Route path="/" element={<Suspense fallback={<>...</>}><ProtectedRoute><DashboardContainer /></ProtectedRoute></Suspense>} />
                <Route path="/forgot-password" element={<Suspense fallback={<>...</>}><ProtectedRoute><ForgotPasswordContainer /></ProtectedRoute></Suspense>} />
                <Route path="/faq" element={<Suspense fallback={<>...</>}><ProtectedRoute><FaqContainer /></ProtectedRoute></Suspense>} />
                <Route path="/page" element={<Suspense fallback={<>...</>}><ProtectedRoute><PageContainer /></ProtectedRoute></Suspense>} />
                <Route path="/profile-setting" element={<Suspense fallback={<>...</>}><ProtectedRoute><ProfileSettingContainer /></ProtectedRoute></Suspense>} />
                <Route path="/role" element={<Suspense fallback={<>...</>}><ProtectedRoute><RoleContainer /></ProtectedRoute></Suspense>} />
                <Route path="/sub-admin" element={<Suspense fallback={<>...</>}><ProtectedRoute><SubAdminContainer /></ProtectedRoute></Suspense>} />
                <Route path="/contact-us" element={<Suspense fallback={<>...</>}><ProtectedRoute><ContactUsContainer /></ProtectedRoute></Suspense>} />
                <Route path="/update-password" element={<Suspense fallback={<>...</>}><ProtectedRoute><UpdatePasswordContainer /></ProtectedRoute></Suspense>} />
                <Route path="/vendor" element={<Suspense fallback={<>...</>}><ProtectedRoute><VendorContainer /></ProtectedRoute></Suspense>} />
                <Route path="/manufacturer" element={<Suspense fallback={<>...</>}><ProtectedRoute><ManufacturerContainer /></ProtectedRoute></Suspense>} />
                <Route path="/category" element={<Suspense fallback={<>...</>}><ProtectedRoute><CategoryContainer /></ProtectedRoute></Suspense>} />
                <Route path="/product" element={<Suspense fallback={<>...</>}><ProtectedRoute><ProductContainer /></ProtectedRoute></Suspense>} />
            </Routes>
        </Box>
    )
}

export default RoutesPath;