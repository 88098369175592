import { combineReducers } from "redux";
import { adminReducer as adminPage } from "./../api/admin/adminReducer";
import { faqReducer as faqPage } from "../api/faq/faqReducer";
import { pageReducer as pagePage  } from "../api/page/pageReducer";
import { roleReducer as rolePage  } from "../api/role/roleReducer";
import { contactUsReducer as contactUsPage } from "../api/contact-us/contact-usReducer";
import { manufacturerReducer as manufacturerPage } from "../api/manufacturer/manufacturerReducer";
import { vendorReducer as vendorPage } from "../api/vendor/vendorReducer";
import { categoryReducer as categoryPage } from "../api/category/categoryReducer";
import { productReducer as productPage } from "../api/product/productReducer";


const appReducer = combineReducers({
    adminPage,
    faqPage,
    pagePage,
    rolePage,
    contactUsPage,
    manufacturerPage,
    vendorPage,
    categoryPage,
    productPage
})

export default appReducer;