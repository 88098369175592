import *  as ActionTypes from "./manufacturerActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    manufacturers: []
};

export const manufacturerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_MANUFATURER:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_MANUFATURER_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_MANUFATURER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_MANUFATURER:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_MANUFATURER_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_MANUFATURER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_MANUFATURER:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_MANUFATURER_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_MANUFATURER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                manufacturers: action?.payload?.data || []
            }
        case ActionTypes.UPDATE_MANUFATURER:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_MANUFATURER_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_MANUFATURER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state
    }
}