import { React, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    Box, Drawer, Toolbar, List,
    Typography,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    Collapse,
    AppBar as MuiAppBar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link, useLocation } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';
import SourceIcon from '@mui/icons-material/Source';
import CallIcon from '@mui/icons-material/Call';
import DiamondIcon from '@mui/icons-material/Diamond';

const drawerWidth = 240;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


export default function Navbar(props) {
    const { open, setOpen, admin } = props;
    const theme = useTheme();
    const location = useLocation();
    const [isMasters, setIsMasters] = useState(false);
    const [isContent, setIsContent] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [isSetting, setIsSetting] = useState(false);
    // const permissions = admin?.Role?.permissions;
    const [permissions] = useState(JSON.parse(admin?.Role?.permissions || "[]"));

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setIsMasters(!isMasters);
    };

    const handleClickContent = () => {
        setIsContent(!isContent);
    };
    const handleClickUser = () => {
        setIsUser(!isUser);
    };
    const handleClickSetting = () => {
        setIsSetting(!isSetting);
    };



    const isActive = (path) => {
        return location.pathname === path;
    };




    return (
        <Box >

            <AppBar
                open={open}
                position='relative'

                sx={{
                    marginTop: '57px',
                    bgcolor: 'white',
                    color: 'black',
                    boxShadow: 1
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerClose}
                        edge="start"
                        sx={{ mr: 2, ...(!open && { display: 'none' }) }}
                    >
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"

                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon onClick={handleDrawerOpen} />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Super Admin Dashboard
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    scrollbarWidth: 'none',
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        scrollbarWidth: 'none',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >

                <Divider />
                {Array.isArray(permissions) && permissions.length > 0 && (
                    <List
                        sx={{
                            marginTop: '57px',
                        }}
                    >
                        {(permissions[0]?.view || permissions[1]?.view) && (
                            <ListItemButton onClick={handleClickContent}>
                                <ListItemIcon>
                                    <SourceIcon />
                                </ListItemIcon>
                                <ListItemText primary="Content" />
                                {isContent ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        )}
                        <Collapse in={isContent} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {permissions[0]?.view && (
                                    <Link to={'/faq'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/faq') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/faq') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>FAQ</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                                {permissions[1]?.view && (
                                    <Link to={'/page'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/page') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/page') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Page</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                            </List>
                        </Collapse>
                        {permissions[2]?.view && (
                            <Link to={'/category'} style={{ textDecoration: 'none' }}>
                                <ListItemButton sx={{
                                    bgcolor: isActive('/category') ? '#FD226E' : '',
                                    pl: 2
                                }}>
                                    <ListItemIcon>
                                        <CategoryIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography sx={{
                                                color: isActive('/category') ? '#FFF' : '',
                                                '&:hover': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}>Category</Typography>
                                        }
                                    />
                                </ListItemButton>
                            </Link>
                        )}
                        {permissions[3]?.view && (
                            <Link to={'/product'} style={{ textDecoration: 'none' }}>
                                <ListItemButton sx={{
                                    bgcolor: isActive('/product') ? '#FD226E' : '',
                                    pl: 2
                                }}>
                                    <ListItemIcon>
                                        <DiamondIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography sx={{
                                                color: isActive('/product') ? '#FFF' : '',
                                                '&:hover': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}>Product</Typography>
                                        }
                                    />
                                </ListItemButton>
                            </Link>
                        )}
                        {(permissions[4]?.view || permissions[5]?.view || permissions[6]?.view || permissions[7]?.view) && (
                            <ListItemButton onClick={handleClickUser}>
                                <ListItemIcon>
                                    <SourceIcon />
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                                {isUser ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        )}
                        <Collapse in={isUser} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {permissions[4]?.view && (
                                    <Link to={'/sub-admin'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/sub-admin') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/sub-admin') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Sub Admin</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                                {permissions[5]?.view && (
                                    <Link to={'/manufacturer'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/manufacturer') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/manufacturer') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Manufacturer</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                                {permissions[6]?.view && (
                                    <Link to={'/vendor'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/vendor') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/vendor') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Vendor</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                                {permissions[7]?.view && (
                                    <Link to={'/customers'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/customers') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                                {/* <PersonOutline /> */}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/customers') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Customers</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}

                            </List>
                        </Collapse>
                        {permissions[8]?.view && (
                            <Link to={'/contact-us'} style={{ textDecoration: 'none' }}>
                                <ListItemButton sx={{
                                    bgcolor: isActive('/contact-us') ? '#FD226E' : '',
                                    pl: 2
                                }}>
                                    <ListItemIcon>
                                        <CallIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography sx={{
                                                color: isActive('/contact-us') ? '#FFF' : '',
                                                '&:hover': {
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                },
                                            }}>Contact Us</Typography>
                                        }
                                    />
                                </ListItemButton>
                            </Link>
                        )}

                        <ListItemButton onClick={handleClickSetting}>
                            <ListItemIcon>
                                <SettingsOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Setting" />
                            {isSetting ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={isSetting} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Link to={'/profile-setting'} style={{ textDecoration: 'none' }}>
                                    <ListItemButton sx={{
                                        bgcolor: isActive('/profile-setting') ? '#FD226E' : '',
                                        pl: 2
                                    }}>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography sx={{
                                                    color: isActive('/profile-setting') ? '#FFF' : '',
                                                    '&:hover': {
                                                        color: 'rgba(0, 0, 0, 0.6)',
                                                    },
                                                }}>Profile</Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </Link>
                                {permissions[8]?.view && (
                                    <Link to={'/role'} style={{ textDecoration: 'none' }}>
                                        <ListItemButton sx={{
                                            bgcolor: isActive('/role') ? '#FD226E' : '',
                                            pl: 2
                                        }}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{
                                                        color: isActive('/role') ? '#FFF' : '',
                                                        '&:hover': {
                                                            color: 'rgba(0, 0, 0, 0.6)',
                                                        },
                                                    }}>Roles</Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </Link>
                                )}
                            </List>
                        </Collapse>
                    </List>
                )}
            </Drawer>

        </Box>
    );
}